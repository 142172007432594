import { AdjustDeviceBidsV2 } from '@opteo/types'
import { InvalidBidReason, InvalidBidReasonRaw, TableItem } from './types'
import { getProperDeviceLabel } from './utils'
import { ref } from 'vue'

export interface TableHeader {
    key: string
    text?: string
    sortable?: boolean
    width?: number
}

export function useDeviceTables(
    devices: AdjustDeviceBidsV2.DeviceMetrics[],
    isUsingCpa: boolean,
    formattedTarget: string,
    entityAverage: number,
    targetSet: boolean
) {
    const deviceTableHeaders: TableHeader[] = [
        { key: 'device', text: 'Device' },
        { key: 'cost', text: 'Cost', sortable: true },
    ]

    if (isUsingCpa) {
        deviceTableHeaders.push(
            { key: 'conversions', text: 'Conv.', width: 96, sortable: true },
            { key: 'cpa', text: 'CPA', sortable: true },
            { key: 'avg-cpa', text: 'Avg. CPA' },
            { key: 'difference', text: 'Diff.', sortable: true, width: 100 },
            { key: 'bid-adjustment', text: 'Bid Adj.', width: 114 }
        )
    } else {
        deviceTableHeaders.push(
            { key: 'conversions-value', text: 'Value', sortable: true, width: 104 },
            { key: 'roas', text: 'ROAS', sortable: true },
            { key: 'avg-roas', text: 'Avg. ROAS' },
            { key: 'difference', text: 'Diff.', sortable: true, width: 100 },
            { key: 'bid-adjustment', text: 'Bid Adj.', width: 132 }
        )
    }

    const deviceAdjustTableHeaders = deviceTableHeaders.map(header => {
        if (header.key === 'bid-adjustment') header.width = 128
        return header
    })

    function mapInvalidReasons(
        rawReason: string | undefined,
        targetSet: boolean,
        performanceModeEntityToCompare: number,
        placementCpa: number,
        placementRoas: number
    ) {
        if (rawReason === InvalidBidReasonRaw.BID_IN_WRONG_DIRECTION && targetSet && isUsingCpa) {
            if (placementCpa < performanceModeEntityToCompare) {
                return {
                    reason: InvalidBidReason.BID_IN_WRONG_DIRECTION_UNDERPERFORMING_CPA.replace(
                        '$TARGET_CPA',
                        formattedTarget
                    ),
                    width: 256,
                }
            }
            return {
                reason: InvalidBidReason.BID_IN_WRONG_DIRECTION_UNDERPERFORMING_CPA.replace(
                    '$TARGET_CPA',
                    formattedTarget
                ),
                width: 256,
            }
        }
        if (rawReason === InvalidBidReasonRaw.BID_IN_WRONG_DIRECTION && targetSet && !isUsingCpa) {
            if (placementRoas < performanceModeEntityToCompare) {
                return {
                    reason: InvalidBidReason.BID_IN_WRONG_DIRECTION_UNDERPERFORMING_ROAS.replace(
                        '$TARGET_ROAS',
                        formattedTarget
                    ),
                    width: 256,
                }
            }
            return {
                reason: InvalidBidReason.BID_IN_WRONG_DIRECTION_UNDERPERFORMING_ROAS.replace(
                    '$TARGET_ROAS',
                    formattedTarget
                ),
                width: 256,
            }
        }
        if (rawReason === InvalidBidReasonRaw.BID_CHANGE_IS_TOO_SMALL) {
            if (isUsingCpa)
                return { reason: InvalidBidReason.BID_CHANGE_IS_TOO_SMALL_CPA, width: 160 }
            return { reason: InvalidBidReason.BID_CHANGE_IS_TOO_SMALL_ROAS, width: 160 }
        }
        if (rawReason === InvalidBidReasonRaw.BUDGET_CAPPED_CONSTRAINTS) {
            return { reason: InvalidBidReason.BUDGET_CAPPED_CONSTRAINTS, width: 169 }
        }
        if (rawReason === InvalidBidReasonRaw.BID_IS_ZERO) {
            return { reason: InvalidBidReason.BID_IS_ZERO, width: 208 }
        }

        return { reason: InvalidBidReason.DEFAULT, width: 200 }
    }

    const deviceTableItems: TableItem[] = []
    const deviceAdjustTableItems: TableItem[] = []

    devices.forEach(device => {
        const cpa =
            device.cost === 0 && device.all_conversions === 0
                ? 0
                : device.cost / device.all_conversions

        const roas =
            device.all_conversions_value > 0 ? device.all_conversions_value / device.cost : 0

        const { reason, width } = mapInvalidReasons(
            device.reason,
            targetSet,
            entityAverage,
            cpa,
            roas
        )

        const difference =
            entityAverage === 0
                ? 0
                : isUsingCpa
                ? (cpa - entityAverage) / entityAverage
                : (roas - entityAverage) / entityAverage

        const row = {
            id: getProperDeviceLabel(device.device_label),
            device: getProperDeviceLabel(device.device_label),
            cost: device.cost,
            conversions: device.all_conversions,
            ['conversions-value']: device.all_conversions_value,
            cpa,
            roas,
            'avg-cpa': entityAverage,
            'avg-roas': entityAverage,
            difference: difference,
            'bid-adjustment': {
                value: !device.invalid ? device.new_bid_mod! - 1 : 0,
                id: device.criteria,
                invalid: device.invalid,
                reason,
                width,
                current: device.current_bid_mod! - 1,
            },
        }

        deviceTableItems.push(row)
        deviceAdjustTableItems.push(row)
    })

    return {
        deviceTableHeaders,
        deviceTableItems,
        deviceAdjustTableHeaders,
        deviceAdjustTableItems,
    }
}
