<template>
    <MobileSplashContainer wide>
        Impact Statistics is available on screens <b>1200px or wider</b>, increase your window size
        or use another device. If you'd like to see us build this feature for touch devices, please
        consider submitting a feature request.
    </MobileSplashContainer>
    <Transition name="fade">
        <div v-if="dataLoading" class="loader">
            <div class="loader-container">
                <!-- Put loader animation or text here-->
                <!--<div class="loader-text">Impact Statistics is gathering your data...</div> -->
                <ImpactStatsLoaderAnimation />
                <div class="progress-bar-container">
                    <!-- There are 5 sets of data to load -->
                    <ProgressBar :progress="loadingProgress * (1 / 5)" />
                </div>
            </div>
        </div>
        <!-- Added the v-else-if condition here to avoid error messages, but given how the loading states are computed it should be safe -->
        <div v-else-if="costSavings && timeSavings && criticalAlerts && userStatistics">
            <PageHeader :no-margin="true" pageTitle="Impact Statistics">
                <template #right-content>
                    <Tabs
                        :links="dateRangeTabs"
                        :router="false"
                        :active="currentDateRange"
                        @clicked="setDateRange"
                    />
                </template>
            </PageHeader>
            <!-- <div>
            <div>
                {{
                    `Loading: costs=${costSavingsLoading} time=${timeSavingsLoading} alerts=${criticalAlertsLoading}`
                }}
            </div>
            <div>
                {{
                    `Validating: costs=${costSavingsValidating} time=${timeSavingsValidating} alerts=${criticalAlertsValidating}`
                }}
            </div>
        </div> -->
            <PageContent>
                <!-- Overview Section -->
                <div class="overview-wrapper">
                    <OverviewSection
                        v-if="timeSavings && criticalAlerts"
                        :costSavings="costSavings"
                        :timeSavings="timeSavings"
                        :criticalAlerts="criticalAlerts"
                        :period="currentDateRange"
                        :costSavingsValidating="costSavingsValidating"
                        :timeSavingsValidating="timeSavingsValidating"
                        :criticalAlertsValidating="criticalAlertsValidating"
                    />
                </div>
                <Spacer height="2.5rem" />
                <!-- Cost Savings -->
                <div class="section-wrapper">
                    <header>
                        <div class="left">
                            <Text as="h4" weight="600">Cost Savings</Text>
                        </div>
                        <oButton color="white" @clicked="downloadCostsCSV">Download CSV</oButton>
                    </header>
                    <Spacer height="2rem" />
                    <CostSectionTable
                        v-if="costsTableData.length > 0"
                        class="impact-statistics-section-table"
                        :data="costsTableData"
                        :validating="costSavingsValidating"
                    />
                    <div v-else class="empty-state-container">
                        <Text as="p" size="f-8" align="center">
                            Keep pushing improvements with Opteo to see your costs savings. Note
                            that not all improvements will be considered in this calculation.
                        </Text>
                    </div>
                </div>
                <Spacer height="2.5rem" />
                <!-- Time Savings -->
                <div class="section-wrapper">
                    <header>
                        <div class="left">
                            <Text as="h4" weight="600">Time Savings</Text>
                        </div>
                        <oButton color="white" @clicked="downloadTimeSavingsCSV"
                            >Download CSV</oButton
                        >
                    </header>
                    <Spacer height="2rem" />
                    <TimeSectionTable
                        v-if="timeTableData.length > 0"
                        class="impact-statistics-section-table"
                        :data="timeTableData"
                        :validating="timeSavingsValidating"
                    />
                    <div v-else class="empty-state-container">
                        <Text as="p" size="f-8" align="center">
                            As you use Opteo to create reports, update RSAs, start experiments, or
                            push improvements, your time savings will appear here.
                        </Text>
                    </div>
                </div>
                <Spacer height="2.5rem" />
                <!-- Critical Alerts -->
                <div class="section-wrapper">
                    <header>
                        <div class="left">
                            <Text as="h4" weight="600">Critical Alerts</Text>
                        </div>
                        <oButton color="white" @clicked="downloadAlertsCSV">Download CSV</oButton>
                    </header>
                    <Spacer height="2rem" />
                    <AlertsSectionTable
                        v-if="alertsTableData.length > 0"
                        class="impact-statistics-section-table"
                        :data="alertsTableData"
                        :validating="criticalAlertsValidating"
                    />
                    <div v-else class="empty-state-container">
                        <Text as="p" size="f-8" align="center">
                            Opteo monitors your accounts to detect unexpected changes, broken links,
                            and other anomalies. Your critical alerts will appear here.
                        </Text>
                    </div>
                </div>
                <Spacer height="2.5rem" />
                <!-- Team Activity -->
                <div class="section-wrapper">
                    <header>
                        <div class="left">
                            <Text as="h4" weight="600">Team Activity</Text>
                        </div>
                        <Popout
                            v-if="teamSize && teamSize > 1"
                            v-model="teamMemberPopoutOpen"
                            :trapFocus="true"
                            :externalToggleButton="true"
                            :offset="[1, 12]"
                        >
                            <oButton
                                color="white"
                                size="large"
                                class="team-member-switcher-button"
                                @clicked="teamMemberPopoutOpen = !teamMemberPopoutOpen"
                            >
                                <div v-if="!selectedUser" class="team-activity-popout-row">
                                    <div class="team-avatars-container">
                                        <ProfileImage
                                            :width="28"
                                            :imageUrl="calendarHeatMap?.userActivities[0].image"
                                            :avatarUrl="
                                                getAvatarUrl(
                                                    calendarHeatMap?.userActivities[0].userId
                                                )
                                            "
                                        />
                                        <ProfileImage
                                            v-if="
                                                calendarHeatMap &&
                                                calendarHeatMap.userActivities.length > 1
                                            "
                                            :width="28"
                                            :imageUrl="calendarHeatMap?.userActivities[1].image"
                                            :avatarUrl="
                                                getAvatarUrl(
                                                    calendarHeatMap?.userActivities[1].userId
                                                )
                                            "
                                        />
                                        <div
                                            v-if="
                                                calendarHeatMap &&
                                                calendarHeatMap.userActivities.length > 2
                                            "
                                            class="extra-team-members"
                                            style="width: 28px; height: 28px"
                                        >
                                            <Text size="f-10" weight="500" align="center">
                                                <span
                                                    >+{{
                                                        calendarHeatMap.userActivities.length - 2
                                                    }}</span
                                                >
                                            </Text>
                                        </div>
                                    </div>
                                    Team
                                </div>
                                <div v-else class="team-activity-popout-row">
                                    <ProfileImage
                                        :width="24"
                                        :imageUrl="selectedUser.image"
                                        :avatarUrl="getAvatarUrl(selectedUser.userId)"
                                    />
                                    <div>{{ selectedUser.name }}</div>
                                </div>
                                <template #icon>
                                    <svg width="12" height="8" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.41 0L6 4.77083333 10.59 0 12 1.46875l-6 6.25-6-6.25z"
                                            fill="#008dff"
                                            fill-rule="evenodd"
                                        />
                                    </svg>
                                </template>
                            </oButton>
                            <template v-slot:content>
                                <SelectableList maxHeight="24rem">
                                    <ListItem
                                        key="team"
                                        @clicked="selectedUser = undefined"
                                        class="team-activity-popout-row"
                                    >
                                        <div class="team-avatars-container">
                                            <ProfileImage
                                                :width="28"
                                                :imageUrl="calendarHeatMap?.userActivities[0].image"
                                                :avatarUrl="
                                                    getAvatarUrl(
                                                        calendarHeatMap?.userActivities[0].userId
                                                    )
                                                "
                                            />
                                            <ProfileImage
                                                v-if="
                                                    calendarHeatMap &&
                                                    calendarHeatMap.userActivities.length > 1
                                                "
                                                :width="28"
                                                :imageUrl="calendarHeatMap?.userActivities[1].image"
                                                :avatarUrl="
                                                    getAvatarUrl(
                                                        calendarHeatMap?.userActivities[1].userId
                                                    )
                                                "
                                            />
                                            <div
                                                v-if="
                                                    calendarHeatMap &&
                                                    calendarHeatMap.userActivities.length > 2
                                                "
                                                class="extra-team-members"
                                                style="width: 28px; height: 28px"
                                            >
                                                <Text size="f-10" weight="500" align="center">
                                                    <span
                                                        >+{{
                                                            calendarHeatMap.userActivities.length -
                                                            2
                                                        }}</span
                                                    >
                                                </Text>
                                            </div>
                                        </div>
                                        <Text size="f-8">Team</Text>
                                    </ListItem>
                                    <ListItem
                                        v-if="calendarHeatMap"
                                        v-for="user of calendarHeatMap.userActivities"
                                        :key="user.userId"
                                        @clicked="selectedUser = user"
                                        class="team-activity-popout-row"
                                        ><ProfileImage
                                            :width="28"
                                            :imageUrl="user.image"
                                            :avatarUrl="getAvatarUrl(user.userId)"
                                        />
                                        <Text size="f-8">{{ user.name }}</Text></ListItem
                                    >
                                </SelectableList>
                            </template>
                        </Popout>
                    </header>
                    <Spacer height="2rem" />
                    <div class="heatmap-wrapper">
                        <HeatmapChart
                            :legend="false"
                            :items="heatmapContent"
                            disclaimerText="Opteo counts Improvements, Reports, RSAs and Created Experiments as activity."
                        />
                    </div>
                </div>
                <Spacer height="2.5rem" />
                <!-- Team Stats -->
                <div class="section-wrapper">
                    <header>
                        <div class="left">
                            <Text as="h4" weight="600">Team Statistics</Text>
                        </div>
                    </header>
                    <Spacer height="2rem" />
                    <oTable
                        :headers="teamStatsTableHeaders"
                        :items="teamStatsTableItems"
                        class="impact-statistics-section-table"
                    >
                        <template v-slot:header.teamMember>
                            <div class="team-member-name-container">Team Member</div>
                        </template>
                        <template v-slot:column.teamMember="cell">
                            <div class="team-member-name-container">
                                <ProfileImage
                                    :width="32"
                                    :isAdmin="cell.value.role === 'admin'"
                                    :image-url="cell.value.imageUrl"
                                    :avatar-url="getAvatarUrl(cell.value.userId)"
                                />
                                <Text as="span" size="f-8" weight="500">{{ cell.value.name }}</Text>
                            </div>
                        </template>
                    </oTable>
                </div>
            </PageContent>
        </div>
    </Transition>
</template>

<script lang="ts">
// Global
import { ref, computed } from 'vue'
import { IS } from '@opteo/types'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import { useAPI, Endpoint } from '@/composition/api/useAPI'

// Local components
import PageHeader from '@/layouts/PageHeader.vue'
import PageContent from '@/layouts/PageContent.vue'
import MobileSplashContainer from '@/components/util/MobileSplashContainer.vue'
import OverviewSection from '@/components/impactStatistics/OverviewSection.vue'
import CostSectionTable from '@/components/impactStatistics/CostSectionTable.vue'
import TimeSectionTable from '@/components/impactStatistics/TimeSectionTable.vue'
import AlertsSectionTable from '@/components/impactStatistics/AlertsSectionTable.vue'
import ImpactStatsAccountNameCell from '@/components/impactStatistics/ImpactStatsAccountNameCell.vue'
import ImpactStatsTimeSavingsTableCell from '@/components/impactStatistics/ImpactStatsTimeSavingsTableCell.vue'
import ProgressBar from '@/components/global/ProgressBar.vue'
import ImpactStatsLoaderAnimation from '@/components/impactStatistics/ImpactStatsLoaderAnimation.vue'

// Composition
import useImpactStatistics from '@/composition/impactStatistics/useImpactStatistics'
import useImpactStatsTimeSavings from '@/composition/impactStatistics/useImpactStatsTimeSavings'

// date-fns
import subtractDays from 'date-fns/subDays'

// components-next
import {
    Spinner,
    Spacer,
    Tabs,
    oButton,
    Text,
    HeatmapChart,
    oTable,
    ProfileImage,
    Popout,
    SelectableList,
    ListItem,
} from '@opteo/components-next'

export default {
    name: 'ImpactStatistics',
    components: {
        // Local
        PageHeader,
        PageContent,
        MobileSplashContainer,
        OverviewSection,
        CostSectionTable,
        TimeSectionTable,
        AlertsSectionTable,
        ImpactStatsAccountNameCell,
        ImpactStatsTimeSavingsTableCell,
        ProgressBar,
        ImpactStatsLoaderAnimation,
        // components-next
        Spinner,
        Spacer,
        Tabs,
        oButton,
        Text,
        HeatmapChart,
        oTable,
        ProfileImage,
        Popout,
        SelectableList,
        ListItem,
    },
    setup() {
        const { formatFromMinutes } = useImpactStatsTimeSavings()

        const { getAvatarUrl } = useBoringAvatar()

        // data
        const {
            // cost savings
            costSavings,
            costSavingsLoading,
            costSavingsValidating,
            costSavingsError,
            // time savings
            timeSavings,
            timeSavingsLoading,
            timeSavingsValidating,
            timeSavingsError,
            // critical alerts
            criticalAlerts,
            criticalAlertsLoading,
            criticalAlertsValidating,
            criticalAlertsError,
            // user statistics
            userStatistics,
            userStatisticsLoading,
            userStatisticsValidating,
            userStatisticsError,
            // calendar
            calendarHeatMap,
            calendarHeatMapLoading,
            calendarHeatMapValidating,
            calendarHeatMapError,
            // time
            setNewDateRangeStart,
            // util
            downloadCSV,
            teamAccounts,
            teamSize,
            getDomainInfoFromList,
            teamAccountsLoading,
            dataLoading,
            loadingProgress,
            team,
        } = useImpactStatistics()

        const downloadTimeSavingsCSV = () => {
            downloadCSV({ file: timeSavings.value?.timeSavingsCSV, name: 'time_savings' })
        }
        const downloadAlertsCSV = () => {
            downloadCSV({
                file: criticalAlerts.value?.criticalAlertCountsCSV,
                name: 'critical_alerts',
            })
        }
        const downloadCostsCSV = () => {
            downloadCSV({
                file: costSavings.value?.costSavingsCSV,
                name: 'costs_savings',
            })
        }

        // Date range selection tabs
        const dateRangeTabs = [
            { key: 'alltime', name: 'All Time', to: '' },
            { key: 'last365days', name: 'Last 365 Days', to: '' },
            { key: 'last90days', name: 'Last 90 Days', to: '' },
        ]
        const currentDateRange = ref('alltime')
        const setDateRange = (range: string) => {
            // For tabs state
            currentDateRange.value = range
            // Changing calculations
            if (range === 'alltime') {
                // Arbitrary date anterior to any Opteo activity
                setNewDateRangeStart(new Date(2000, 0))
            } else if (range === 'last365days') {
                setNewDateRangeStart(subtractDays(new Date(), 365))
            } else if (range === 'last90days') {
                setNewDateRangeStart(subtractDays(new Date(), 90))
            } else
                throw new Error(
                    `Date range = ${range}, should be either 'alltime', 'last365days', or 'last90days'.`
                )
        }

        // Cost section
        const costsTableData = computed(() => {
            const tableRows = []
            for (const [key, value] of Object.entries(costSavings.value?.domainCostSavings ?? {})) {
                // Retrieve extra information from list of domains
                const domainInfo = getDomainInfoFromList(+key)
                const countWithPauseSpend =
                    value.pauseSpend.hasBudgetAutoPause ||
                    value.pauseSpend.monthlyCostSavingsUSD > 0
                        ? value.total.count + 1
                        : value.total.count // Adds 1 when pause spend is on OR there are savings associated with it
                tableRows.push({
                    id: key,
                    accountName: domainInfo,
                    impCount: countWithPauseSpend,
                    avgSavedPerImp:
                        countWithPauseSpend > 0
                            ? value.total.monthlyCostSavingsUSD / countWithPauseSpend
                            : 0,
                    totalCostSaved: value.total.monthlyCostSavingsUSD,
                    accountBreakdown: {
                        improvements: value.improvements.instances,
                        pauseSpend: value.pauseSpend,
                        domainInfo: domainInfo,
                        domainId: key,
                    },
                })
            }
            return tableRows
        })

        const timeTableData = computed(() => {
            const tableRows = []
            for (const [key, value] of Object.entries(timeSavings.value?.domainTimeSavings ?? {})) {
                // Retrieve extra information from list of domains
                const domainInfo = getDomainInfoFromList(+key)
                tableRows.push({
                    id: key,
                    accountName: domainInfo,
                    totalTimeSaved: value.totalMinutesSaved,
                    improvements: value.improvementsCount,
                    improvementsMinutesSaved: value.improvementsMinutesSaved,
                    reports: value.reportsCount,
                    reportsMinutesSaved: value.reportsMinutesSaved,
                    //experiments: value.biddingExperimentsCount,
                    //experimentsMinutesSaved: value.biddingExperimentsMinutesSaved,
                    //rsas: value.createdRsaCount,
                    //rsasMinutesSaved: value.createdRsaMinutesSaved,
                    toolActions:
                        value.createdRsaCount +
                        value.biddingExperimentsCount +
                        value.scorecardsCount,
                    toolActionsMinutesSaved:
                        value.createdRsaMinutesSaved +
                        value.biddingExperimentsMinutesSaved +
                        value.scorecardsMinutesSaved,
                })
            }
            return tableRows
        })

        const alertsTableData = computed(() => {
            //console.log(Object.entries(criticalAlerts.value?.domainCriticalAlertsCount))
            const tableRows = []
            for (const [key, value] of Object.entries(
                criticalAlerts.value?.domainCriticalAlertCounts ?? {}
            )) {
                // Retrieve extra information from list of domains
                const domainInfo = getDomainInfoFromList(+key)
                tableRows.push({
                    id: key,
                    accountName: domainInfo,
                    budget: value.budgetAlertCount,
                    kpiAnom: value.deltaAlertCount,
                    zeroImpr: value.flatlineAlertCount,
                    brokenLink: value.brokenLinkImprovementCount,
                    accountBreakdown: { instances: value.instances, domainInfo: domainInfo },
                })
            }
            return tableRows
        })

        // heatmap
        type User = {
            calendarHeatMap: object
            image: string
            name: string
            role: string
            userId: number | null | undefined
            username: string
        }
        const teamMemberPopoutOpen = ref(false)
        const selectedUser = ref<undefined | User>(undefined)
        const heatmapContent = computed<IS.HeatmapChartItem[]>(() => {
            if (!selectedUser.value)
                return calendarHeatMap.value?.teamActivities
                    .calendarHeatMap as IS.HeatmapChartItem[]
            else return selectedUser.value.calendarHeatMap as IS.HeatmapChartItem[]
        })

        // team stats
        const teamStatsTableHeaders = [
            { key: 'teamMember', text: 'Team Member', noPadding: true },
            //{ key: 'email', text: 'Email' },
            { key: 'sessions', text: 'Sessions', sortable: true },
            { key: 'improvements', text: 'Improvements', sortable: true },
            { key: 'rsas', text: 'RSAs', sortable: true },
            { key: 'reports', text: 'Reports', sortable: true },
        ]

        const teamStatsTableItems = computed(() => {
            const tableRows = []

            const userStatsArray = userStatistics.value?.userStatistics
            if (userStatsArray === undefined) return

            for (let i = 0; i < userStatsArray.length; i++) {
                const userData = team.value?.find(item => item.user_id === userStatsArray[i].userId)

                tableRows.push({
                    key: userData?.user_id,
                    teamMember: {
                        name: userStatsArray[i].name,
                        role: userStatsArray[i].role,
                        userId: userStatsArray[i].userId,
                        imageUrl: userData?.avatar_filename ?? '',
                    },
                    email: userStatsArray[i].username,
                    sessions: userStatsArray[i].sessionCount,
                    improvements: userStatsArray[i].pushedImprovementCount,
                    rsas: userStatsArray[i].createdRsaCount + userStatsArray[i].updatedRsaCount,
                    reports: userStatsArray[i].createdReportCount,
                })
            }

            return tableRows
        })

        return {
            dateRangeTabs,
            setDateRange,
            currentDateRange,
            costsTableData,
            timeTableData,
            alertsTableData,
            teamAccounts,
            teamSize,
            downloadCostsCSV,
            downloadTimeSavingsCSV,
            downloadAlertsCSV,
            teamAccountsLoading,
            dataLoading,
            loadingProgress,
            getAvatarUrl,
            // cost savings
            costSavings,
            costSavingsLoading,
            costSavingsValidating,
            costSavingsError,
            // time savings
            timeSavings,
            timeSavingsLoading,
            timeSavingsValidating,
            timeSavingsError,
            // critical alerts
            criticalAlerts,
            criticalAlertsLoading,
            criticalAlertsValidating,
            criticalAlertsError,
            // user statistics
            userStatistics,
            userStatisticsLoading,
            userStatisticsValidating,
            userStatisticsError,
            teamStatsTableHeaders,
            teamStatsTableItems,
            // calendar heatmap
            calendarHeatMap,
            calendarHeatMapLoading,
            calendarHeatMapValidating,
            calendarHeatMapError,
            heatmapContent,
            teamMemberPopoutOpen,
            selectedUser,
            // date
            setNewDateRangeStart,
        }
    },
}
</script>

<!-- Scoped -->
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.overview-wrapper {
    @include container;
    @include br-32;
    @include pa-40;
}
.section-wrapper {
    @include container;
    @include br-32;
    @include pt-28;
    @include ph-40;
    @include pb-40;
}
.section-wrapper header {
    @include flex;
    @include items-center;
    @include justify-between;
}
.section-wrapper header .left {
    @include flex;
    @include items-center;
}

.team-activity-popout-row {
    @include flex;
    gap: 0.5rem;
    align-items: center;
}

.heatmap-wrapper {
    @include container;
    @include br-24;
    @include pt-24;
    @include ph-20;
    @include pb-16;
}

.team-avatars-container {
    @include flex;
    gap: 0.25rem;
}

.extra-team-members {
    @include container;
    position: relative;
    margin-right: 0.375rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-member-name-container {
    @include flex;
    @include items-center;
    @include ml-24;
    gap: 1rem;
}

.empty-state-container {
    @include w-100;
    @include flex-center;
    @include pv-40;
    p {
        max-width: 31.25rem;
        @include container;
        @include br-16;
        @include pv-28;
        @include ph-32;
    }
}

// Loader
.loader {
    @include block;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loader .o-input {
    @include block;
}

.loader-container {
    max-width: 42.5rem;
    // halfScreen - halfFooterPlusMargin - halfElement
    //margin: calc(50vh - 3.25rem - 14.5rem) auto 0 auto;
    width: 100%;
    height: 100%;
}

.loader .progress-bar-container {
    @include ph-64;
    @include mt-64;
}

.loader-text {
    text-align: center;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.48s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>

<!-- Unscoped -->
<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
// Unscoped to allow adjustments to oTable children
.impact-statistics-section-table td:first-child {
    width: 100%;
}
.impact-statistics-section-table .table-search-container {
    @include flex;
    @include items-center;
}
.impact-statistics-section-table .table-search-label {
    text-align: left;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: -0.0063rem;
    margin: 0 0 0 24px;
}
// To center image and text inside button
.team-member-switcher-button span {
    @include flex;
}
</style>
