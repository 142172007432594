import { format } from 'date-fns'

import { Scorecard } from '@opteo/types'
import { IS_DEVELOPMENT } from '@/lib/env'

export interface DonutData {
    name: string
    y: number
}

export interface TextOptions {
    [score: number]: string[] | string | undefined
}

export const formatDonutChartData = (donutChart: DonutData[]) => {
    return (donutChart ?? []).map(({ y, name }) => {
        return { y, label: name }
    })
}

export const getSectionCopy = (textOptions: TextOptions, score: number) => {
    let copy = textOptions[0]
    for (const optionScore in textOptions) {
        if (score >= +optionScore) {
            copy = textOptions[+optionScore]
        }
    }

    return Array.isArray(copy) ? copy : [copy ?? '']
}

export const getCreationMonth = (date: string) => {
    return format(new Date(date), 'LLLL yyyy')
}

export const getAccountInitials = (coverAccountName: string | undefined) => {
    return coverAccountName ? coverAccountName.slice(0, 2) : ''
}

export const formatBreakdown = <T extends { campaign: string }>(items: T[]) => {
    const breakdown: Map<string, typeof items> = new Map()

    items.forEach(item => {
        const { campaign } = item
        const itemsInCampaign = breakdown.get(campaign) || []

        itemsInCampaign.push(item)
        breakdown.set(campaign, itemsInCampaign)
    })

    return Array.from(breakdown)
}

export const sectionMiniCopyGetters: Record<
    Scorecard.SectionType,
    (details?: Scorecard.AllDetails) => TextOptions
> = {
    sqr: details => {
        const isSqrScoreDetails = (
            details?: Scorecard.AllDetails
        ): details is Scorecard.SqrScoreDetails => {
            return (details as Scorecard.SqrScoreDetails)?.noSearchTerms !== undefined
        }
        if (isSqrScoreDetails(details) && details.noSearchTerms) {
            return {
                0: 'No search term data available. This is expected if you use Performance Max or Shopping campaigns exclusively.',
            }
        }
        return {
            0: 'There is a significant opportunity to add relevant keywords from search terms and reduce overall CPA.',
            25: 'Some queries are matched by keywords. However, some ads may be less-than-relevant to searchers.',
            75: 'Most of the search queries directing traffic to your site are matched by existing keywords in your account.',
        }
    },
    qs: () => ({
        0: 'Quality Scores across your account could improve. Low Quality Scores usually mean a higher overall CPA.',
        50: 'Quality Scores are satisfactory across your account. CPC could be reduced with some improvements.',
        70: 'Quality Scores are performing well, they play a key role in keeping CPC low and spending efficiently.',
    }),
    projectedSpend: details => {
        const isProjectedSpendScoreDetails = (
            details?: Scorecard.AllDetails
        ): details is Scorecard.ProjectedSpendScoreDetails => {
            return (details as Scorecard.ProjectedSpendScoreDetails)?.spend_status !== undefined
        }

        if (isProjectedSpendScoreDetails(details) && details.spend_status === 'above') {
            return {
                70: "You're on target to hit, or come very close to hitting budget this month. Aim to maintain consistent spend.",
                50: 'Your account is looking at a significant overspend this month. Aim to reduce wasted spend wherever possible.',
                0: 'Your account is looking at a significant overspend this month. Aim to reduce wasted spend wherever possible.',
            }
        }

        if (isProjectedSpendScoreDetails(details) && details.spend_status === 'below') {
            return {
                70: "You're on target to hit, or come very close to hitting budget this month. Aim to maintain consistent spend.",
                50: 'Your account should underspend this month. Spending seems cautious when considering your budget.',
                0: 'Your account is looking at a significant underspend this month. Look to boost spend across your account.',
            }
        }

        if (isProjectedSpendScoreDetails(details) && details.spend_status === 'on') {
            return {
                0: "You're on target to hit, or come very close to hitting budget this month. Aim to maintain consistent spend.",
            }
        }

        return {
            0: 'Your account does not have a monthly budget set. Consider setting a monthly budget to track spending over time.',
        }
    },
    budgetCap: () => ({
        99: 'No campaigns in your account are limited by budget, this means ads are competing for all available traffic.',
        70: 'Some of your campaigns are limited by budget. They may perform better with some minor adjustments.',
        0: 'A significant number of campaigns are budget limited. Performance could improve with some adjustments.',
    }),
    matchtype: details => {
        const isMatchTypeSpreadScoreDetails = (
            details?: Scorecard.AllDetails
        ): details is Scorecard.MatchTypeSpreadScoreDetails => {
            return (
                (details as Scorecard.MatchTypeSpreadScoreDetails)?.exact_score !== undefined &&
                (details as Scorecard.MatchTypeSpreadScoreDetails)?.non_broad_score !== undefined
            )
        }

        if (
            isMatchTypeSpreadScoreDetails(details) &&
            (details.exact_score ?? 0) > (details.non_broad_score ?? 0)
        ) {
            return {
                70: 'You have a healthy mix of both exact and phrase match keywords, ensuring budget is allocated appropriately.',
                50: 'Your account is allocating too much budget to broad keywords. Ads may be less-than-relevant to searchers.',
                0: 'Your account is spending excessively through broad keywords. Ads may be less-than-relevant to searchers.',
            }
        }

        return {
            70: 'You have a healthy mix of both exact and phrase match keywords, ensuring budget is allocated appropriately.',
            50: 'You should be allocating more budget to exact match keywords to ensure relevant searchers are targeted.',
            0: 'You are not allocating enough budget to exact match type keywords that directly target relevant searchers.',
        }
    },
    adExtension: () => ({
        70: 'Your account is making good use of ad extensions. Test different extensions to find profitable combinations.',
        50: 'Some campaigns have ad extensions applied but many do not. We should aim for full ad extension coverage.',
        1: 'Few campaigns in your account have ad extensions applied. Increase your ad visibility with extensions.',
        0: 'No campaigns in your account have ad extensions applied. Increase your ad visibility with extensions.',
    }),
    conversionTracking: () => ({
        33: 'Conversion tracking is active and has tracked enough conversions to provide meaningful performance data.',
        1: 'Too few conversions have been recorded in your account to provide meaningful performance data.',
        0: 'Ensure conversion tracking is set up to measure profitability and inform decisions moving forward.',
    }),
    performanceMax: () => ({
        70: `You have provided Google's machine learning with all of the necessary assets and audience signals.`,
        0: `Aim to provide Google's machine learning with all of the necessary assets and audience signals.`,
    }),
    productPerformance: () => ({
        0: 'A good score indicates that budget is being allocated towards products that are performing well.',
    }),
    disapprovals: () => ({
        100: 'All of your keywords, extensions, and ads have been approved. Remember to address disapprovals promptly, as they can lead to account suspension.',
        0: 'Your account has some disapprovals that require attention. Address these disapprovals as soon as possible to avoid issues like account suspension.',
    }),
    integrations: () => ({
        90: 'Your account is taking advantage of enhanced keyword data, improved conversion tracking, and more.',
        0: 'Connect your account to external data sources for better conversion tracking, enhanced keyword data, and more.',
    }),
    searchTermAlignment: () => ({
        70: 'Search terms, RSA copy and landing page copy overlap well, leading to higher conversion rates.',
        0: 'Customise keywords, RSA copy, or landing pages to improve search term overlap and boost engagement.',
    }),
    landingPages: () => ({
        0: 'Check for broken urls or redirects to decrease bounce rates and improve overall landing page performance.',
        100: 'Landing pages across the account are in good shape and have been successfully checked for broken urls.',
    }),
}

export const getMiniCopy = (
    sectionType: Scorecard.SectionType,
    sectionDetails?: Scorecard.AllDetails
) => {
    const getCopyForTypeFunc = sectionMiniCopyGetters[sectionType]

    if (!getCopyForTypeFunc) {
        if (Object.keys(Scorecard.SectionTypes).includes(sectionType)) {
            const filePath = new URL(import.meta.url).pathname
            const message = `Copy not defined for ${sectionType} section`
            const messageWithFile = `${message}, see file ${filePath}`
            throw new Error(IS_DEVELOPMENT ? messageWithFile : message)
        }
        return { 0: 'No copy defined for section' }
    }

    return getCopyForTypeFunc(sectionDetails)
}

export const extractSenderInfo = (scorecardBody: Scorecard.ScorecardBody | undefined) => {
    const {
        userId,
        senderEmail = '',
        senderName = '',
        senderLogoUrl = '',
    } = scorecardBody?.coverPageData || {}

    return {
        userId,
        senderEmail,
        senderName,
        senderLogoUrl,
    }
}

export const handleIsScorecardAvailable = (scorecard: Scorecard.SingleScorecardFull) => {
    const { account } = scorecard
    const senderInfo = extractSenderInfo(scorecard.body)

    const scorecardExists = !!Object.values(scorecard).length
    const accountExists = !!Object.values(account).length
    let senderExists = Object.values(senderInfo).some(value => !!value)

    if (senderInfo.userId === 0) {
        senderExists = true
    }

    return scorecardExists && accountExists && senderExists
}
