export enum InvalidBidReasonRaw {
    BID_IS_ZERO = 'BID_IS_ZERO',
    BID_CHANGE_IS_TOO_SMALL = 'BID_CHANGE_IS_TOO_SMALL',
    BID_IN_WRONG_DIRECTION = 'BID_IN_WRONG_DIRECTION',
    BUDGET_CAPPED_CONSTRAINTS = `WON'T_BID_UP_FOR_ALREADY_BUDGET_CONSTRAINED_CAMPAIGNS`,
    MINIMUM_CLICKS_NOT_MET = `MINIMUM_CLICKS_NOT_MET`,
}

export enum InvalidBidReason {
    BID_IN_WRONG_DIRECTION_OUTPERFORMING_CPA = `No adjustment proposed because segment CPA is performing better than the parent campaign group Target CPA (<b>$TARGET_CPA</b>).`,
    BID_IN_WRONG_DIRECTION_UNDERPERFORMING_CPA = `No adjustment proposed because segment CPA is underperforming compared to your campaign group Target CPA (<b>$TARGET_CPA</b>).`,
    BID_IN_WRONG_DIRECTION_OUTPERFORMING_ROAS = `No adjustment proposed because segment ROAS is performing better than the parent campaign group Target ROAS (<b>$TARGET_ROAS</b>).`,
    BID_IN_WRONG_DIRECTION_UNDERPERFORMING_ROAS = `No adjustment proposed because segment ROAS is underperforming compared to your campaign group Target ROAS (<b>$TARGET_ROAS</b>).`,
    BUDGET_CAPPED_CONSTRAINTS = `No adjustment proposed because parent campaign is limited by budget.`,
    BID_CHANGE_IS_TOO_SMALL_CPA = 'No adjustment proposed because segment CPA is within 3% of average.',
    BID_CHANGE_IS_TOO_SMALL_ROAS = 'No adjustment proposed because segment ROAS is within 3% of average.',
    BID_IS_ZERO = 'No adjustment proposed because existing segment bid equals zero.',
    DEFAULT = 'No adjustment proposed due to insufficient <b>clicks</b> in segment.',
}

export interface TableHeader {
    key: string
    text?: string
    sortable?: boolean
    width?: number
}

export interface TableItem {
    id: string
    device: string
    cost: number
    conversions: number
    ['conversions-value']: number
    cpa: number
    roas: number
    'avg-cpa': number
    'avg-roas': number
    difference: number
    'bid-adjustment': {
        value: number
        id: number
        invalid: boolean
        reason: string
        width: number
        current?: number
    }
}

export type Entity = 'adgroup' | 'campaign' | 'campaign-group'
