<template>
    <div class="container">
        <div class="layout">
            <AnalyticsTableColumn
                :entity-type="AnalyticsTypes.MatadataEntityType.Account"
                :entities="accounts"
                :filtered-entities="filtered?.accounts"
                :selected-entity="selected?.account"
                :select-entity="selectEntity"
                :search-entities="searchEntities"
            />
            <AnalyticsTableColumn
                :entity-type="AnalyticsTypes.MatadataEntityType.Property"
                :entities="selected?.account?.properties"
                :filtered-entities="filtered?.properties"
                :selected-entity="selected?.property"
                :select-entity="selectEntity"
                :search-entities="searchEntities"
            />
            <AnalyticsTableColumn
                :entity-type="AnalyticsTypes.MatadataEntityType.Profile"
                :entities="selected?.property?.profiles"
                :filtered-entities="filtered?.profiles"
                :selected-entity="selected?.profile"
                :select-entity="selectEntity"
                :search-entities="searchEntities"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import AnalyticsTableColumn from './AnalyticsTableColumn.vue'
import { AnalyticsTypes } from '@opteo/types'

export default {
    components: { AnalyticsTableColumn },
    props: {
        accounts: {
            type: Array as PropType<AnalyticsTypes.FormattedAccountData[]>,
        },
        selected: {
            type: Object as PropType<{
                account?: AnalyticsTypes.FormattedAccountData
                property?: AnalyticsTypes.FormattedAccountProperty
                profile?: AnalyticsTypes.MatadataEntityBase
            }>,
        },
        filtered: {
            type: Object as PropType<{
                accounts?: AnalyticsTypes.FormattedAccountData[]
                properties?: AnalyticsTypes.FormattedAccountProperty[]
                profiles?: AnalyticsTypes.MatadataEntityBase[]
            }>,
        },
        selectEntity: {
            type: Function as PropType<
                (type: AnalyticsTypes.MatadataEntityType, entityId: string) => void
            >,
            required: true,
        },
        searchEntities: {
            type: Function as PropType<
                (type: AnalyticsTypes.MatadataEntityType, searchQuery: string) => void
            >,
            required: true,
        },
    },
    setup() {
        return { AnalyticsTypes }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.container {
    @include w-100;
    @include container;
    height: 100%;
}

.layout {
    @include grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media screen and (max-width: $mq-767-max) {
    .layout {
        @include grid;
        grid-template-columns: 1fr;
    }
    .container {
        height: auto;
    }
}
</style>
