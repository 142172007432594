<template>
    <div class="column-container">
        <Text as="p" size="f-8" weight="500" class="table-cell head">
            {{ title }}
        </Text>
        <div v-if="(entities ?? []).length > 8" class="search">
            <oInput
                :ref="entityType"
                v-model="searchQuery"
                type="text"
                :class="inputClass"
                :name="entityType"
                autocomplete="off"
            />
        </div>
        <div
            v-for="entity in filteredEntities"
            :key="entity.id"
            @click="selectEntity(entityType, entity.id)"
            :class="[
                {
                    selected: !!selectedEntity && entity.id === selectedEntity.id,
                },
                'table-cell',
            ]"
        >
            <div class="text-container">
                <Text as="p" size="f-8"> {{ entity.name }}</Text>
                <Text as="p" style="opacity: 0.3; font-size: 12px">
                    {{ entity.id }}
                </Text>
            </div>
            <div class="checkbox">
                <CheckIcon
                    checkable
                    animated
                    :checked="!!selectedEntity && entity.id === selectedEntity.id"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { AnalyticsTypes } from '@opteo/types'
import { computed, ref, watch } from 'vue'
import { Text, CheckIcon, oInput } from '@opteo/components-next'

const props = defineProps<{
    entityType: AnalyticsTypes.MatadataEntityType
    entities?: AnalyticsTypes.MatadataEntityBase[]
    filteredEntities?: AnalyticsTypes.MatadataEntityBase[]
    selectedEntity?: AnalyticsTypes.MatadataEntityBase
    selectEntity: (type: AnalyticsTypes.MatadataEntityType, entityId: string) => void
    searchEntities: (type: AnalyticsTypes.MatadataEntityType, searchQuery: string) => void
}>()

const searchQuery = ref('')

watch(searchQuery, update => props.searchEntities(props.entityType, update))

const title = computed(() => {
    let entityTitle
    switch (props.entityType) {
        case AnalyticsTypes.MatadataEntityType.Account:
            entityTitle = 'Google Analytics Account'
            break
        case AnalyticsTypes.MatadataEntityType.Property:
            entityTitle = 'Properties & Apps'
            break
        case AnalyticsTypes.MatadataEntityType.Profile:
            entityTitle = 'Views & Data Streams'
            break
    }
    return entityTitle
})

const inputClass = computed(() =>
    props.entityType === AnalyticsTypes.MatadataEntityType.Profile
        ? 'views-search-input'
        : 'search-input'
)
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.container {
    @include w-100;
    @include container;
    height: 100%;
}

.layout {
    @include grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.column-container {
    border-right: 1px solid;
    @include opteo-border;
    min-height: 640px;
}

.column-container:last-child {
    border-right: unset;
}

.table-cell {
    @include flex;
    @include items-center;
    @include justify-between;
    @include w-100;
    white-space: nowrap;
    @include pa-24;
    border-bottom: 1px solid;
    @include opteo-border;
    @include pointer;
    transition: 0.3s background-color ease;
}

.table-cell:hover {
    background-color: rgba(250, 250, 252, 1);
}

.table-cell.head {
    letter-spacing: -0.24px;
}

.text-container {
    overflow: hidden;
}

.head:hover {
    background-color: unset;
    cursor: default;
}

.checkbox {
    flex-shrink: 0;
}

// Search
.search {
    @include bg-opteo-white;
    border-bottom: 1px solid $opteo-light-gray;
    position: sticky;
    top: -2rem;
    @include pa-20;
    @include z-2;
}
.search-input,
.views-search-input {
    @include relative;
}

.search-input :deep(input),
.views-search-input :deep(input) {
    padding: 10px 14px 10px 38px;
}

.search-input::before,
.views-search-input::before {
    content: '';
    position: absolute;
    left: 14px;
    top: 0;
    bottom: 0;
    width: 12px;
    background: url('@/assets/img/searchIcon.svg') center / contain no-repeat;
}

@media screen and (max-width: $mq-767-max) {
    .column-container {
        border-bottom: 2px solid;
        @include opteo-border;
        min-height: unset;
    }
    .column-container:last-child {
        border-bottom: unset;
    }
}
</style>
